import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout-wiki/index.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Audio`}</h1>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "audio-clips"
        }}>{`Audio Clips`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "audio-events"
        }}>{`Audio Events`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "bouncing"
        }}>{`Bouncing`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "recording"
        }}>{`Recording`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      